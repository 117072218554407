import config from './config.js';
import observeResources from './observeResources.js';
import {round, max, isNumber} from './utils.js';

export default function collectResources([, , PerformanceObserver, setTimeout, clearTimeout], entryType,
    {filter, tag = true, debounce = config.resourceDebounce} = {}) {

    const promise = new Promise(resolve => {
        const resources = [];

        let timer = setTimeout(done, debounce);

        const observer = observeResources(PerformanceObserver, (entries, finish) => {
            if (filter) {
                entries = entries.filter(filter);
            }
            if (entries.length) {
                resources.push(...entries);
                clearTimeout(timer);
                timer = setTimeout(() => {
                    done();
                    finish();
                }, debounce);
            }
        });

        function done() {
            const extra = observer?.takeRecords?.();
            if (extra) {
                resources.push(...extra);
            }
            resolve(resources);
        }
    }).then(resources => {
        if (!resources.length) {
            return {
                entryType,
                count: 0
            };
        }

        const {tbd, firstResponse, lastResponse} = resources.reduce((acc, {transferSize, responseStart, responseEnd}) => ({
            tbd: acc.tbd + transferSize,
            firstResponse: responseStart > 0 && responseStart < acc.firstResponse ? responseStart : acc.firstResponse,
            lastResponse: max(acc.lastResponse, responseEnd)
        }), {
            tbd: 0,
            firstResponse: 1000000,
            lastResponse: 0
        });

        const ttfbs = resources
            .filter(({requestStart}) => isNumber(requestStart))
            .map(({requestStart, responseStart}) => responseStart - requestStart)
            .sort();
        const {length} = ttfbs;
        const half = length >> 1;

        const count = resources.length;
        const result = {
            entryType,
            count,
            startTime: round(firstResponse),
            duration: round(lastResponse - firstResponse),
            mttfb: round(length % 2 ? ttfbs[half] : (ttfbs[half - 1] + ttfbs[half]) / 2),
            attfb: round(ttfbs.reduce((acc, ttfb) => acc + ttfb, 0) / length)
        };
        if (!Number.isNaN(tbd)) {
            result.tbd = tbd;
        }
        return result;
    }, () => ({
        entryType,
        count: 0
    }));

    return tag ? tagToAvoidConflicts(promise, entryType) : promise;
}

function tagToAvoidConflicts(promise, entryType) {
    const tagLabel = capitalize(entryType);
    return promise.then(result => Object.entries(result).reduce((acc, [key, value]) => {
        acc[key === 'entryType' ? key : key + tagLabel] = value;
        return acc;
    }, {}));
}

function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
}