import {MARKER, LISTEN, LISTEN_ONCE} from './constants.js';

export function noop() {}

export const label = s => `${MARKER}${s}`;

export function rejector(value) {
    return () => Promise.reject(value);
}

export const {round, max, min} = Math;

function isType(type) {
    return v => typeof v === type;
}
export const isUndefined = isType('undefined');
export const isNumber = isType('number');
export const isBoolean = isType('boolean');
export const isString = isType('string');

export function addEventListener(target, type, listener, once = true) {
    target.addEventListener(type, listener, once ? LISTEN_ONCE : LISTEN);
}
export function removeEventListener(target, type, listener, once = true) {
    target.removeEventListener(type, listener, once ? LISTEN_ONCE : LISTEN);
}

export function getFirstEntryByType(performance, entryType) {
    return performance.getEntriesByType && performance.getEntriesByType(entryType)[0];
}

export function allFulfilled(promises) {
    return Promise.allSettled(promises).then(results => results
        .filter(({status}) => status === 'fulfilled')
        .map(({value}) => value));
}

export function closestId(element, id) {
    if (isString(id)) {
        return id;
    }
    const closest = element && element.closest('*[id]');
    if (closest) {
        return closest.id;
    }
}

export function batch(entryType, ...promises) {
    return new Promise(resolve => {
        allFulfilled(promises).then(values => {
            const result = values.reduce((acc, value) => Object.assign(acc, value), {});
            result.entryType = entryType;
            resolve(result);
        });
    });
}
