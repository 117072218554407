import {isUndefined} from '../utils/utils.js';
import {load} from '../utils/windowEvents.js';

const entryType = 'wix';

const IS_SSR = 'is_ssr';
const IS_ROLLOUT = 'is_rollout';
const IS_PLATFORM_LOADED = 'is_platform_loaded';
const VIEWER_NAME = 'viewer_name';

const FIELDS = [
    'cdn',
    'dc',
    'microPop',
    'caching',
    IS_ROLLOUT,
    IS_PLATFORM_LOADED,
    'maybeBot'
];
const MAP_NAME = {
    [IS_ROLLOUT]: 'isRollout',
    [IS_PLATFORM_LOADED]: 'isPlatformLoaded',
    [IS_SSR]: 'isSsr',
    [VIEWER_NAME]: 'viewerName'
};

export default function wix([window]) {
    return load(window).then(() => {
        const wixBiSession = window.wixBiSession || window.bi?.wixBiSession;
        if (!wixBiSession) {
            throw entryType;
        }

        const result = {
            entryType
        };

        const {rendererModel, clientSideRender, ssrInfo, thunderboltVersion} = window;

        addField('msid', rendererModel?.metaSiteId || wixBiSession.msId);
        addField('pageId', rendererModel?.landingPageId || window.firstPageId);

        const version = thunderboltVersion || window.boltVersion;
        addField('v', version);
        addField(IS_SSR, !clientSideRender);
        if (ssrInfo) {
            addField('ssrDuration', ssrInfo.timeSpentInSSR);
        }
        addField(VIEWER_NAME, thunderboltVersion ? 'thunderbolt' : wixBiSession.renderType);
        FIELDS.forEach(field => addField(field, wixBiSession[field]));

        return result;

        function addField(key, value) {
            if (!isUndefined(value)) {
                const name = MAP_NAME[key] || key;
                result[name] = /^is_/.test(key) ? !!value : value;
            }
        }
    });
}
